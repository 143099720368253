<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Acessos de Instrutores
        </h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-end">
          <router-link class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'create-banner' }">
            Novo acesso
          </router-link>
        </div>
        <v-client-table ref="table" :data="users" :columns="columns" :options="options">
          <div class="flex" slot="actions" slot-scope="{ row, index }">
            <router-link class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'edit-ads', params: { id: row.id } }" v-tooltip="{ content: 'Editar', placement: 'left' }">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                  fill="rgba(0,122,111,1)" />
              </svg>
            </router-link>
            <button class="flex p-1 border-2 border-green-1002 w-max rounded-lg" @click="deleteinstructor(row, index)"
              v-tooltip="{ content: 'Apagar Instrutor', placement: 'left' }">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"
                  fill="rgba(0,122,111,1)" />
              </svg>
            </button>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>
<script>
  import DashboardLayout from '../../../layouts/DashboardLayout';
  import axios from '@/utils/axios';
  import LoaderAnimation from '@/components/LoaderAnimation';
  
  export default {
    name: 'list-ads',
  
    title() {
      return `${process.env.VUE_APP_NAME} | Associados`;
    },
  
    components: {
      DashboardLayout,
      LoaderAnimation
    },
  
  
    data() {
      return {
        isLoading: false,
        users: [],
        columns: [
          'id',
          'name',
          'email',
          'status',
          'actions'
        ],
        options: {
          sortable: true,
          perPage: 10,
          perPageValues: [10, 15, 20, 25, 50],
          headings: {
            name: 'Nome',
            email: 'E-mail',
            status: 'Status',
            actions: 'Ação'
          },
          hiddenColumns: ['id'],
          sortIcon: {
            is: 'glyphicon-sort',
            base: 'glyphicon',
            up: 'glyphicon-chevron-up',
            down: 'glyphicon-chevron-down'
          },
          texts: {
            first: 'Primeiro',
            last: 'Último',
            filter: 'Filtrar:',
            filterBy: 'Filtrar por {column}',
            filterPlaceholder: 'Pesquise aqui...',
            count:
              '{from} - {to} de {count} campanhas|{count} campanhas|Uma campanha',
            limit: 'Registros por página:',
            page: 'Página:',
            noResults: 'Nenhuma campanha cadastrada.',
            columns: 'Colunas'
          },
          pagination: {
            chunk: 5
          }
        }
      };
    },
  methods: {

    async getUsers() {
      await axios.get('/api/usuarios').then(({ data }) => {
          data.data.forEach(users => {
            this.users.push(users);
          });
        });
    },

    edituser: function(id) {
      this.$router.push({ name: 'user-edit', params: { id: id } });
    }
  },
  created: function() {
    this.getUsers();
  }
};
</script>

<style scoped></style>
